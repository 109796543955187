import React from "react";
import "./GoogleMap.scss";

function GoogleMap({ src, height, width }) {
  return (
    <div className="boxMap">
      <div id="map">
        <div className="text">
          <h2>See if we're near you!</h2>
          <p>
            Use the map to explore our service area and make sure we're
            available in your location. We're ready to transform your space
          </p>
        </div>

        <div class="mapContainer">
          <div class="borderTop"></div>
          <iframe
            src={src}
            width={width}
            height={height}
            className="googleMap"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          <div class="borderDown"></div>
        </div>
      </div>
    </div>
  );
}

export default GoogleMap;
