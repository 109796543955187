import React from "react";
import { useNavigate } from "react-router-dom";
import "./Footer.scss";
import { footer__data } from "../../../myFolder/editComponents/other/footerData";
function Footer() {
  const navigate = useNavigate();

  const navigateToSection = (section) => {
    navigate("/services");
    setTimeout(() => {
      document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
    }, 200); // Retraso para asegurar la carga de la página
  };
  const navigateToContact = (contact) => {
    navigate("/contact");
    window.scroll(0, 0);
  };

  const { socialMedias, mainColors, websiteName } = footer__data; // Asegúrate de tener estas propiedades en footer__data

  return (
    <div
      className="footer"
      style={{
        backgroundColor: footer__data.mainColors.backgroundColor,
        color: footer__data.mainColors.textColor,
      }}
    >
      <div className="section-top">
        <div className="rectangleNumber">
          <div className="textContainer">
            <div className="textUpdateYourFloor">Upgrade Your Floor</div>
            <div className="GetWiseToday">Act smartly today!</div>
          </div>
          <div className="boxNumber">
            <div className="button">
              <button onClick={() => navigateToContact("contact")}>
                <div className="getAQuote">Get A Quote</div>
              </button>
            </div>
            <a href="tel:+14069462673" className="phoneLink">
              <i className="fa-solid fa-phone"></i> Call us at: (406) 946-2673
            </a>
          </div>
        </div>
      </div>

      <div className="boxToServices">
        {/* Section 1 */}
        <div className="section-right">
          <div className="boxCoatings">
            <h2>Smart Coatings</h2>
            <h6>
              We transform your spaces with quality and precision: experts in
              drywall, paint, and epoxy floors.
              <p>
                <i className="fa-solid fa-location-dot"></i> 518 Greenway Ave,
                Bozeman, Montana 59718
              </p>
              <p className="emails">
                <i className="fa-solid fa-envelope"></i>{" "}
                edp@epoxydrywallandpaint.com
              </p>
            </h6>
          </div>
        </div>

        {/* End section 1 */}
        <div className="middle-section">
          <div className="boxServices">
            <h2>Services</h2>
            <div className="textTheServices">
              <button
                className="buttons"
                onClick={() => navigateToSection("epoxy")}
              >
                <div className="textEpoxy">
                  <i className="fa-solid fa-chevron-right"></i> Epoxy floor.
                </div>
              </button>
              <button
                className="buttons"
                onClick={() => navigateToSection("drywall")}
              >
                <div className="textDrywall">
                  <i className="fa-solid fa-chevron-right"></i> DryWall.
                </div>
              </button>
              <button
                className="buttons"
                onClick={() => navigateToSection("paint")}
              >
                <div className="textPaint">
                  <i className="fa-solid fa-chevron-right"></i> Paint.
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="section-left">
          <div className="logoImageContainer">
            <img
              src={footer__data.logoImage.image}
              alt="Logo"
              className="logoImage"
              draggable="false"
              style={{ height: footer__data.logoImage.height }}
            />
            <div className="allSocialMediasContainer">
              {socialMedias.map((socialMedia, index) => {
                return (
                  <a
                    href={socialMedia.socialMediaLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="socialMediaIconContainer"
                    key={index}
                    style={
                      mainColors.socialMediaIconsColor === ""
                        ? { color: mainColors.socialMediaIconsColor }
                        : mainColors.socialMediaIconsColor === "custom"
                        ? { color: socialMedia.iconCustomColor }
                        : { color: mainColors.socialMediaIconsColor }
                    }
                  >
                    {socialMedia.icon}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Sección de redes sociales */}

      {/* Sección de copyright */}
      <div className="copyrightSection">
        <div className="copyrightAndBusinessName">
          &copy; 2024 {websiteName} | ALL RIGHTS RESERVED
        </div>
        <div className="createdBySection">
          This website was created by Whistle Coding
        </div>
      </div>
    </div>
  );
}

export default Footer;
