import React from "react";

// Importación de Datos
import {
  giammariaCard__data,
  serviceCards__data,
} from "../myFolder/editComponents/cards/cardsData";
import {
  ITC_customEpoxyFloors,
  ITC_drywall,
  ITC_painting,
} from "../myFolder/editComponents/other/data_ImageAndTextContainer";

import ImageAndTextContainer from "../preMadeComponents/otherComponents/ImageAndTextContainer/ImageAndTextContainer";
import CardsContainer from "../preMadeComponents/containers/CardsContainer/CardsContainer";
import AllCards from "../preMadeComponents/cards/AllCards";
import Footer from "../preMadeComponents/otherComponents/Footer/Footer";

function ServicesPage() {
  return (
    <div>
      <div id="epoxy">
        <ImageAndTextContainer data={ITC_customEpoxyFloors} />
      </div>

      <CardsContainer
        scroll={true}
        marginTop={40}
        gapBetween={10}
        title={"Colors"}
        titlePosition={"Center"}
      >
        {giammariaCard__data.cards.map((card, index) => (
          <AllCards key={index} card={card} card__data={giammariaCard__data} />
        ))}
      </CardsContainer>

      <div id="drywall">
        <ImageAndTextContainer data={ITC_drywall} />
      </div>

      <div id="paint">
        <ImageAndTextContainer data={ITC_painting} />
      </div>

      <Footer />
    </div>
  );
}

export default ServicesPage;
